import { Params, redirect } from "react-router-dom";
import { SIMPLE_HASH_NETWORK_BY_CHAIN_ID } from "@/utils/networks";
import { NFTContract, SimpleHashNFT } from "@/utils/types";
import { WALLET_ADDRESS_REGEX } from "@/utils/validation";
import { DynamicUserProfile } from "@/types/dynamic";

const SUPPORTED_CHAINS = Object.values(SIMPLE_HASH_NETWORK_BY_CHAIN_ID).join(
  ",",
);

const SIMPLE_HASH_API_KEY =
  "legitimate_sk_cb24a3ee-b1ae-4b9d-acad-28433473d4f1_b8aw1es44uso7uyz";

const ALL_NFTS_IN_WALLETS_API = new URL(
  `https://api.simplehash.com/api/v0/nfts/owners?chains=${SUPPORTED_CHAINS}&wallet_addresses=`,
);

const NFT_SERVER_BASE_URL = "https://api.legitimate.tech";

const ALL_LGT_CONTRACTS_API = new URL(
  "https://metadata-api.legitimate.tech/external/v1/contracts",
);

export const collectionLoader = async ({
  params: { walletAddressOrUsername },
}: {
  params: Params<string>;
}) => {
  if (!walletAddressOrUsername) {
    return redirect("/");
  }

  const response = await fetch(ALL_LGT_CONTRACTS_API);
  const allLgtNfts = await response.json();

  const primaryContractSet = new Set(
    allLgtNfts.contracts.map((c: NFTContract) =>
      c.nft_contract_address.toLowerCase(),
    ),
  );

  let walletAddress;
  let user: DynamicUserProfile | undefined = undefined;

  if (WALLET_ADDRESS_REGEX.test(walletAddressOrUsername)) {
    walletAddress = walletAddressOrUsername;

    const response = await fetch(
      `${NFT_SERVER_BASE_URL}/dynamic/user?address=${walletAddressOrUsername}`,
    );

    const data = await response.json();

    if (data.user?.username) {
      return redirect(`/${data.user.username}`);
    }

    user = data.user;
  } else {
    const response = await fetch(
      `${NFT_SERVER_BASE_URL}/dynamic/user?username=${walletAddressOrUsername}`,
    );

    const data = await response.json();

    if (data.user?.address) {
      walletAddress = data.user.address;
      user = data.user;
    } else {
      return redirect("/");
    }
  }

  const nfts = [] as SimpleHashNFT[];

  let cursor = "<start>";

  while (cursor) {
    const nftResp = await fetch(`${ALL_NFTS_IN_WALLETS_API}${walletAddress}`, {
      headers: {
        "X-API-KEY": SIMPLE_HASH_API_KEY,
      },
    });

    const jsonResp = await nftResp.json();
    nfts.push(...jsonResp.nfts);

    cursor = jsonResp.cursor;
  }

  return {
    user,
    primaryNfts: nfts.filter((nft) =>
      primaryContractSet.has(nft.contract_address.toLowerCase()),
    ),
  };
};
