export enum NETWORK {
  ETHEREUM = 1,
  ETHEREUM_SEPOLIA = 11155111,
  POLYGON = 137,
  POLYGON_AMOY = 80002,
  AVALANCHE = 43114,
  AVALANCHE_FUJI = 43113,
  ARBITRUM_ONE = 42161,
  ARBITRUM_ONE_SEPOLIA = 421614,
  ARBITRUM_NOVA = 42170,
  BASE = 8453,
  BASE_SEPOLIA = 84532,
  LAMINA1 = 10849,
  LAMINA1_FUJI = 764984,
}

export const SIMPLE_HASH_NETWORK_BY_CHAIN_ID = {
  [NETWORK.ETHEREUM]: "ethereum",
  [NETWORK.ETHEREUM_SEPOLIA]: "ethereum-sepolia",
  [NETWORK.POLYGON]: "polygon",
  [NETWORK.POLYGON_AMOY]: "polygon-amoy",
  [NETWORK.AVALANCHE]: "avalanche",
  [NETWORK.AVALANCHE_FUJI]: "avalanche-fuji",
  [NETWORK.ARBITRUM_ONE]: "arbitrum",
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: "arbitrum-sepolia",
  [NETWORK.ARBITRUM_NOVA]: "arbitrum-nova",
  [NETWORK.BASE]: "base",
  [NETWORK.BASE_SEPOLIA]: "base-sepolia",
};
