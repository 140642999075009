import { SimpleHashNFT } from "../utils/types";

interface WalletItemProps {
  nft: SimpleHashNFT;
}

const WalletItem: React.FC<WalletItemProps> = ({ nft }) => {
  return (
    <div>
      <img
        src={nft.image_url}
        alt={nft.name}
        className="w-full h-auto mb-4 rounded-[10px]"
      />
      <h3 className="font-abcFavorit text-2xl font-semibold mb-1 truncate">
        {nft.name}
      </h3>
      <p className="font-abcFavorit text-sm text-[#202020] mb-4 line-clamp-2">
        {nft.description}
      </p>
    </div>
  );
};

export default WalletItem;
